import React, { useState, useContext } from 'react';
import fetchData from '../../global/FetchData';
import './Home.scss';
import { AppContext } from '../../global/context'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {CircleFill} from 'react-bootstrap-icons';

// import logo from '../../assets/images/logo-prodabit-transp.png'

function Home({setLoading, setTitle}) {

  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState([]);    
  const {token, getActiveProcard, atualizar} = useContext(AppContext);    

  React.useEffect(() => {
    
    //-- Adiciona Script de Segurança MPago dentro do body
    // const script = document.createElement('script');
    // script.src = 'https://www.mercadopago.com/v2/security.js';
    // script.setAttribute("view", "home");
    // document.body.appendChild(script);        
    ChartJS.register(ArcElement, Tooltip, Legend);
    
    const loadAll = async () => {
      
      const active = getActiveProcard()            
      setTitle('Resumo Consumo Procard :: Dashboard');      
      //const procard = getActiveProcard()
      const res = await fetchData.getResumoConsumo({ id_procard: active.id }, token);   

      //-- Sessão expirada
      if(res.code === 401){
        setLoading(false);
        sessionStorage.clear();    
        window.location.href = '/';      
      }      

      //-- Seta os dados para a tabela de produtos mais consumidos
      if (res.items.mais_consumidos) {
        setTableData(res.items.mais_consumidos)
      }

      
      if(res.items.nutricao){
        const aux = res.items.nutricao.map((item) => {
          return parseInt(item.qtde)
        })            

        const chartDadosAux = {
          datasets: [{
            data: aux,
            //data: [10,16,12,3],
            borderWidth: 1,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)', //green
              'rgba(54, 162, 235, 0.2)', //blue
              'rgba(153, 102, 255, 0.2)', //purple
              'rgba(255, 99, 132, 0.2)', //red
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 99, 132, 1)',
            ]
          }],
        };

        //-- carrega os dados para o gráfico
        setChartData(chartDadosAux)
      }
    }
    loadAll();

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 400);
    return () => clearTimeout(timeout);

  }, [token, setLoading, setTitle, getActiveProcard, atualizar])

  

  const processaTipo = (nutri_id) => {
        
    const colors = {
        1: 'rgba(75, 192, 192, 0.2)', //green
        2: 'rgba(54, 162, 235, 0.2)', //blue
        3: 'rgba(153, 102, 255, 0.2)', //purple
        4: 'rgba(255, 99, 132, 0.2)', //red
    }
    
    switch(nutri_id){                        
        case '2': return <CircleFill style={{color: colors[2]}}/>;
        case '3': return <CircleFill style={{color: colors[3]}}/>;
        case '4': return <CircleFill style={{color: colors[4]}}/>;
        default: return <CircleFill style={{color: colors[1]}}/>;
    }
  }


  return (
      <div className="home--container">
        <div className="chart">
          <h4 className="mb-4 mt-1 text-muted text-center">Tipo Produtos Consumidos</h4>

          <div className="chart-container">
            {chartData &&
              <Doughnut
                data={chartData}
                options={{ responsive: true, aspectRatio: 1 }}
              />}
          </div>

          <div className='legenda'>
            <h5 className="mb-3">Classificação dos Alimentos</h5>
            <ul>
              <li><span></span><br />++<br />Saudável</li>
              <li><span></span><br />+-<br />Nutritivo</li>
              <li><span></span><br />--<br />Médio</li>
              <li><span></span><br />---<br />Fast Food</li>
            </ul>
            <div>Fonte: Cardápio Saudável</div>
          </div>
        </div>

        {/* -- tabela com produtos consumidos --  */}
        <div className="tabela">
          <h4><span className="text-muted">(Os 10 produtos + consumidos)</span></h4>
          <div className="table-responsive pt-3">
            <table className="table table-striped table-sm">
              <thead>
                <tr>
                  <th width="30%" className="text-left">Produto/Descrição</th>
                  <th width="30%" className="text-left">Categoria</th>
                  <th width="15%" className="text-left">Tipo</th>
                  <th width="15%" className="text-left">Qtde.</th>
                </tr>
              </thead>
              <tbody>
                {tableData && tableData.map((item, key) => {
                  
                  // var cor = '';                  
                  // switch (item.nutri_id){                    
                  //   case '1':  cor = '#ade6de'; break;
                  //   case '2':  cor = '#aad7f4'; break;
                  //   case '3':  cor = '#d2bffc'; break;
                  //   case '4':  cor = '#fbbdcb'; break;
                  //   default:   cor = '#ade6de'; 
                  // } 
                  
                  return (
                    <tr key={key}>
                      <td width="38%" className="text-left">{item.descricao}</td>
                      <td width="42%" className="text-left">{item.categoria}</td>                      
                      <td width="10%">{processaTipo(item.nutri_id)}</td>
                      <td width="10%" className="text-left">{item.qtde}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='legend-source text-left'>Dados dos últimos 60 dias.</div>
        </div>
      </div>
  )
}


export default Home;