import React, { useContext } from "react";
import fetchData from "../../global/FetchData";
import "./Config.scss";
import { AppContext } from "../../global/context";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

function Config({ setLoading, setTitle }) {
    
    const MySwal = withReactContent(Swal);
    const { token } = useContext(AppContext);
    const [state, setState] = React.useState({    
      id: 0,
      stripe_chave_publica: "", stripe_chave_privada: "", stripe_webhook: "",
      mpago_chave_publica: "", mpago_chave_privada: "", mpago_webhook: "", 
      cielo_merchant_id: "", cielo_merchant_key: "", king_pass: "",
      preco1: "", preco2: "", preco3: "", preco4: "", preco5: "", preco6: "",
      chave_preco1: "", chave_preco2: "", chave_preco3: "", chave_preco4: "", chave_preco5: "", chave_preco6: "",      
      recarga_online: "0", recarga_mpago: "0", recarga_stripe: "0", recarga_cielo: "0",
      background_color: "", card_color: "", font_color: "", background_img: "", logo: "",
      email_notif1: "", email_notif2: "", desconto: 0, acrescimo: 0,
      pgto_cartao: "1", pgto_pix: "1", pgto_boleto: "1", pgto_paypal: "1",
      show_cardapio: "1", show_dash: "1", show_limite_diario: "0", home: "dash", link_cardapio: "", produtos_proibidos: "0"
    });     


    React.useEffect(() => {
      
        setTitle("Configurações do Sistema");
        const loadData = async () => {
        setLoading(true);        
        const retorno = await fetchData.getConfig(token);  
        // console.log(retorno.items);      
        
        //-- Sessão expirada
        if(retorno.code === 401){
            setLoading(false);
            sessionStorage.clear();    
            window.location.href = '/';      
        }
        
        setState(retorno.items)        
        setLoading(false);
      }; 
      loadData();         
    }, [setLoading, setTitle, token]);    
    
 


    function handleChange(evt) {
      const value = evt.target.value;
      setState({...state, [evt.target.name]: value});    
    }

    function handleCheckboxChange(evt) {
      const value = evt.target.checked ? "1" : "0";
      setState({...state, [evt.target.name]: value});    
   }

  

    async function handleSubmit(event) {        
      
      setLoading(true);  
      event.preventDefault();
        
      //console.log(state);
      const retorno = await fetchData.saveConfig(state, token);
      if (retorno.data.status === "error") {
          MySwal.fire({
              title: "Oops!",
              html: retorno.data.message,
              icon: "error",
          });
          return;
      }
      setLoading(false); 
    //   setState({...state, ['king_pass']: ""});        
      MySwal.fire({
        title: "Tudo certo!",
        html: retorno.data.message,
        icon: "success",
    });
    }


    return (
        <form onSubmit={handleSubmit}>
            
            <div className="row justify-content-end mt-3">
                <div className="col-12 col-sm-3 me-2">
                    <button type="submit" className="btn btn-success float-end">
                        Salvar Configurações
                    </button>
                </div>                
            </div>
                

            <Tabs defaultActiveKey="home" id="tab-config-geral" className="config-tabs m-3">
                <Tab eventKey="home" title="Home">
                    <section className="config-section">
                        <div className="left-box">

                             <div className="row mb-3">
                                <div className="col-4 col-sm-1 pe-0">
                                    <label htmlFor="background_color" className="form-label">Fundo Tela</label>                            
                                    <input name="background_color" 
                                        type="color" 
                                        className="form-control form-control-color" 
                                        value={state.background_color} 
                                        onChange={handleChange} 
                                        title="Escolha a cor" 
                                    />                            
                                </div>
                                <div className="col-4 col-sm-1 pe-0">
                                    <label htmlFor="card_color" className="form-label">Card Principal</label>                            
                                    <input name="card_color" 
                                        type="color" 
                                        className="form-control form-control-color" 
                                        value={state.card_color} 
                                        onChange={handleChange} 
                                        title="Escolha a cor" 
                                    />                            
                                </div>
                                <div className="col-4 col-sm-1 pe-0">
                                    <label htmlFor="font_color" className="form-label">Fonte Login</label>                            
                                    <input name="font_color" 
                                        type="color" 
                                        className="form-control form-control-color" 
                                        value={state.font_color} 
                                        onChange={handleChange} 
                                        title="Escolha a cor" 
                                    />   
                                </div>                                
                            </div>
                            
                            <div className="row mb-3">
                                <div className="col-12 col-sm-3 pe-0 mb-3">
                                    <label htmlFor="background_img" className="form-label">
                                        Imagem Fundo Login
                                    </label>
                                    <input name="background_img" type="text" className="form-control" value={state.background_img} onChange={handleChange} />
                                </div>
                                
                                
                                <div className="col-12 col-sm-3 pe-0 mb-3">
                                    <label htmlFor="logo" className="form-label">
                                        Logo Empresa (L360xH270px)
                                    </label>
                                    <input name="logo" type="text" className="form-control" value={state.logo} onChange={handleChange} />
                                </div>
                            </div>
                            

                            <div className="row mb-3">
                                <div className="col-12 col-sm-3 mb-3 me-1 pe-sm-0">
                                    <label htmlFor="email_notif1" className="form-label">EMail Notificação 1</label>                            
                                    <input name="email_notif1"
                                        type="email" 
                                        className="form-control"                                 
                                        value={state.email_notif1} 
                                        onChange={handleChange}                                 
                                    />                               
                                </div>
                                <div className="col-12 col-sm-3 mb-3">
                                    <label htmlFor="email_notif2" className="form-label">EMail Notificação 2</label>                            
                                    <input name="email_notif2"
                                        type="email" 
                                        className="form-control"                                 
                                        value={state.email_notif2} 
                                        onChange={handleChange}                                 
                                    />                               
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-sm-3 pe-0 mb-3">
                                    <label htmlFor="home" className="form-label">
                                        Página Home
                                    </label>
                                    <select name="home" class="form-select" aria-label="home select" value={state.home} onChange={handleChange}>                                         
                                        <option value="dash">DashBoard</option>
                                        <option value="senha">Alterar Senha</option>
                                        <option value="compras">Compras Realizadas</option>
                                        <option value="produtos">Produtos Consumidos</option>
                                        <option value="recargas">Relatório de Recargas</option>
                                        <option value="recarga">Realizar Recarga</option>
                                        <option value="cardapio">Cardápio</option>
                                    </select>                                    
                                </div>
                                
                                <div className="col-12 col-sm-3 pe-0 mb-3">
                                    <label htmlFor="link_cardapio" className="form-label">
                                        Link Cardápio (completo com http://)
                                    </label>
                                    <input name="link_cardapio" type="text" className="form-control" value={state.link_cardapio} onChange={handleChange} />
                                </div>

                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="king_pass" className="form-label">
                                        Senha Genérica:
                                    </label>
                                    <input name="king_pass" type="password" className="form-control"  value={state.king_pass} onChange={handleChange} maxLength="8"/>                                    
                                </div>
                            </div>                            

                            <div className="row">
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="show_dash" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.show_dash === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Exibir Dashboard
                                        </label>
                                    </div>
                                </div> 
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="show_cardapio" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.show_cardapio === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Exibir Cardápio
                                        </label>
                                    </div>                               
                                </div>    
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="show_limite_diario" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.show_limite_diario === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Exibir Limite Diário
                                        </label>
                                    </div>                               
                                </div>   

                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="produtos_proibidos" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.produtos_proibidos === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Permite Proibir Produtos
                                        </label>
                                    </div>                               
                                </div>   
                            </div> 
                        </div>

                        <div className="right-box"></div>
                    </section>
                </Tab>
                <Tab eventKey="chaves" title="Pagamentos/Chaves">
                    <section className="config-section p-2">
                        <div className="left-box">

                            <div className="row">
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="recarga_online" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.recarga_online === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Habilitar Pagamentos OnLine
                                        </label>
                                    </div>
                                </div> 
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="recarga_cielo" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.recarga_cielo === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Habilitar Recarga Cielo
                                        </label>
                                    </div>                               
                                </div>    
                            </div> 
                            <div className="row">
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="recarga_mpago" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.recarga_mpago === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Habilitar Recebimento Mercado Pago
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3 pe-0 mb-3">
                                    <div className="form-check">
                                        <input name="recarga_stripe" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.recarga_stripe === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Habilitar Recebimento Stripe
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div className="row">
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="pgto_cartao" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.pgto_cartao === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Cartão de Crédito
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="pgto_pix" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.pgto_pix === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Recebimento Pix
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="pgto_boleto" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.pgto_boleto === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Recebimento Boleto
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3 pe-0 mb-2">
                                    <div className="form-check">
                                        <input name="pgto_paypal" 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            checked={state.pgto_paypal === "1"}
                                            onChange={handleCheckboxChange}                             
                                        />
                                        <label className="form-check-label">
                                        Recebimento PayPal
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <br />  


                            {/*Chaves Cielo  */}
                            <div className="row mb-3">
                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="cielo_merchant_id" className="form-label">
                                        Cielo MerchantID
                                    </label>
                                    <input name="cielo_merchant_id" type="text" className="form-control" value={state.cielo_merchant_id} onChange={handleChange} />
                                    <div id="merchantid_help" className="form-text">
                                        Chave obtida no dashboard Cielo.
                                    </div>
                                </div>

                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="cielo_merchant_key" className="form-label">
                                        Cielo MerchantKey
                                    </label>
                                    <input name="cielo_merchant_key" type="text" className="form-control" value={state.cielo_merchant_key} onChange={handleChange} />
                                </div>
                            </div>  

                            {/*Chaves Mercado Pago  */}
                            <div className="row mb-3">
                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="mpago_chave_publica" className="form-label">
                                        Chave Pública MPago
                                    </label>
                                    <input name="mpago_chave_publica" type="text" className="form-control" value={state.mpago_chave_publica} onChange={handleChange} />
                                    <div id="emailHelp" className="form-text">
                                        Chave obtida no dashboard Mercado Pago.
                                    </div>
                                </div>

                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="mpago_chave_privada" className="form-label">
                                        Chave Privada MPago
                                    </label>
                                    <input name="mpago_chave_privada" type="text" className="form-control" value={state.mpago_chave_privada} onChange={handleChange} />
                                </div>

                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="mpago_webhook" className="form-label">
                                        Webhook MPago
                                    </label>
                                    <input name="mpago_webhook" type="text" className="form-control" value={state.mpago_webhook} onChange={handleChange} />
                                </div>
                            </div>  


                            <div className="row">
                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="stripe_chave_publica" className="form-label">
                                        Chave Pública Stripe
                                    </label>
                                    <input name="stripe_chave_publica" type="text" className="form-control" value={state.stripe_chave_publica} onChange={handleChange} />
                                    <div id="emailHelp" className="form-text">
                                        Chave obtida no dashboard Stripe.
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="stripe_chave_privada" className="form-label">
                                        Chave Privada Stripe
                                    </label>
                                    <input name="stripe_chave_privada" type="text" className="form-control" value={state.stripe_chave_privada} onChange={handleChange} />
                                </div>
                                <div className="col-12 col-sm-3 pe-0  mb-3">
                                    <label htmlFor="stripe_webhook" className="form-label">
                                        Chave Webhook Stripe
                                    </label>
                                    <input name="stripe_webhook" type="text" className="form-control" value={state.stripe_webhook} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </section>                
                </Tab>
                <Tab eventKey="valores" title="Valores">
                    <section className="config-section">
                        <div className="left-box">

                            <div className="row">                            
                                <div className="col-6 col-sm-3 pe-0 mb-3">
                                    <label htmlFor="desconto" className="form-label">
                                        [-] Desconto Recarga (%)
                                    </label>
                                    <input name="desconto" type="text" className="form-control" value={state.desconto} onChange={handleChange} />
                                    <div id="emailHelp" className="form-text">
                                        % de desconto aplicado ao valor da recarga.
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 pe-0 mb-3">
                                    <label htmlFor="acrescimo" className="form-label">
                                        [+] Acréscimo Recarga (%)
                                    </label>
                                    <input name="acrescimo" type="text" className="form-control" value={state.acrescimo} onChange={handleChange} />
                                    <div id="emailHelp" className="form-text">
                                        % de acréscimo aplicado ao valor da recarga.
                                    </div>
                                </div>
                            </div>
                            <hr/>

                            <div className="row mb-0">                            
                                <p className="mt-4">Valor Líquido recarga | Chave de Preços Stripe</p>
                                <hr className="mb-3" />
                            </div>

                            <div className="row mb-2">
                                <div className="col-4 col-sm-3 pe-0">
                                    <input
                                        className="form-control stripe-price"
                                        type="number"
                                        name="preco1"
                                        value={state.preco1} onChange={handleChange}
                                    />
                                </div>
                                <div className="col-8 col-sm-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chave_preco1"
                                        value={state.chave_preco1} onChange={handleChange}
                                        placeholder="Chave do valor cadastrado Stripe"
                                    />
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-4 col-sm-3 pe-0">
                                    <input
                                        className="form-control stripe-price"
                                        type="number"
                                        name="preco2"
                                        value={state.preco2} onChange={handleChange}
                                    />
                                </div>
                                <div className="col-8 col-sm-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chave_preco2"
                                        value={state.chave_preco2} onChange={handleChange}
                                        placeholder="Chave do valor cadastrado Stripe"
                                    />
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-4 col-sm-3 pe-0">
                                    <input
                                        className="form-control stripe-price"
                                        type="number"
                                        name="preco3"
                                        value={state.preco3} onChange={handleChange}
                                    />
                                </div>
                                <div className="col-8 col-sm-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chave_preco3"
                                        value={state.chave_preco3} onChange={handleChange}
                                        placeholder="Chave do valor cadastrado Stripe"
                                    />
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-4 col-sm-3 pe-0">
                                    <input
                                        className="form-control stripe-price"
                                        type="number"
                                        name="preco4"
                                        value={state.preco4} onChange={handleChange}
                                    />
                                </div>
                                <div className="col-8 col-sm-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chave_preco4"
                                        value={state.chave_preco4} onChange={handleChange}
                                        placeholder="Chave do valor cadastrado Stripe"
                                    />
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-4 col-sm-3 pe-0">
                                    <input
                                        className="form-control stripe-price"
                                        type="number"
                                        name="preco5"
                                        value={state.preco5} onChange={handleChange}
                                    />
                                </div>
                                <div className="col-8 col-sm-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chave_preco5"
                                        value={state.chave_preco5} onChange={handleChange}
                                        placeholder="Chave do valor cadastrado Stripe"
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-4 col-sm-3 pe-0">
                                    <input
                                        className="form-control stripe-price"
                                        type="number"
                                        name="preco6"
                                        value={state.preco6} onChange={handleChange}
                                    />
                                </div>
                                <div className="col-8 col-sm-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chave_preco6"
                                        value={state.chave_preco6} onChange={handleChange}
                                        placeholder="Chave do valor cadastrado Stripe"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="right-box"></div>
                    </section>
                </Tab>
                
            </Tabs>
        </form>
    );
}

export default Config;
