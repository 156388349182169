import React from 'react';
import ReactLoading from 'react-loading';


function Loading(){
    return(        
        <ReactLoading className="loading" type="spin" color="#cb4191" height={'60px'} width={'60px'} />        
    )
} 
    

export default Loading;