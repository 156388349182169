import React, { useEffect } from 'react';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'

function MPagoCheckout({mp_public_key, preferenceId}) {

    
    //-- Não está funcionando esta customização. Depois dar uma conferida no motivo.
    //-- Também não está abrindo em modo modal
    const customization = {
        checkout: {
          theme: {
            elementsColor: '#4287F5',
            headerColor: '#4287F5'
          },
        },
    }

    useEffect(() => {
        initMercadoPago(mp_public_key, {locale: 'pt-BR'});    
    }, [])

    return (               
        <div>
            <Wallet 
                initialization={{ preferenceId: preferenceId, redirectMode: 'modal' }}
                customization={customization} 
            />
        </div>
    )
}

export default MPagoCheckout