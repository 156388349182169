import React, {useState, useContext} from 'react';
import { AppContext } from '../../global/context'
import RecargaDesativada from './RecargaDesativada'
import './Pagamentos.scss';
import MPagoCheckout from './MPagoCheckout';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {CashCoin} from 'react-bootstrap-icons';
import {Check} from 'react-bootstrap-icons';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import fetchData from '../../global/FetchData';
//import { ArrowRight } from 'react-bootstrap-icons';


function Pagamentos({setLoading, setTitle, deviceId}) {     

  const [valor, setValor] = useState('preco3'); 
  const [fpgto, setFPgto] = useState('mpago'); 
  const [finalizar] = useState(false);
  const [config, setConfig] = useState({});   
  const [preferenceId, setPreferenceId] = useState('');     
  const { getActiveProcard, token } = useContext(AppContext);
  const MySwal = withReactContent(Swal)
  

  React.useEffect(() => {      
    
    //-- Adiciona Script de Segurança MPago dentro do body
    const script = document.createElement('script');
    script.src = 'https://www.mercadopago.com/v2/security.js';
    script.setAttribute("view", "search");
    document.body.appendChild(script);    
    
    setTitle('Realizar Recarga Procard');
    const loadConfig = async () => {
      setLoading(true);        
      const retorno = await fetchData.getConfig(token);  
      
      //-- Sessão expirada
      if(retorno.code === 401){
        setLoading(false);
        sessionStorage.clear();    
        window.location.href = '/';      
      }

      setConfig(retorno.items) 
      //-- Seta a forma de pagamento checked by default       
      if(retorno.items.recarga_mpago === '1') setFPgto('mpago')
      else if(retorno.items.recarga_cielo === '1') setFPgto('cielo')
      else if(retorno.items.recarga_stripe === '1') setFPgto('stripe')

      //console.log(retorno.items)
      // setLoading(false);
    }; 
    loadConfig();         
  }, [setLoading, setTitle, token]);  
  
  
  //-- Aguarda 3 Segundos para que o código de segurança MPago seja gerado
  React.useEffect(() => {
    
    if(!deviceId || deviceId.current.value === ""){
      setLoading(true);
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
    else{
      setLoading(false);
    }
  }, [setLoading, deviceId])  
  
  

  function handleSubmit(event){
    event.preventDefault(); 
    // const xvalor = ((valor.replace('R$', '')).replace(',', '.')).trim();    

    if(valor === ''){
      setLoading(false);
      MySwal.fire({
        title: 'Oops!',
        html: 'Selecione o valor da recarga!',
        icon: 'warning'     
      })
      return;
    }

    if(fpgto !== 'pix' && fpgto !== 'stripe' && fpgto !== "mpago" && fpgto !== "cielo"){
      setLoading(false);
      MySwal.fire({
        title: 'Oops!',
        html: 'Selecione uma forma de pagamento!',
        icon: 'warning'     
      })
      return;
    }
    
    const procard = getActiveProcard();
    const device_id = deviceId.current.value ? deviceId.current.value : '';
    const data = {valor, fpgto, deviceId: device_id, procard_id: procard.id}
    executeSubmit(data);
  }

  
  async function executeSubmit(data){
    // <form action="/create-checkout-session.php" method="POST"></form>
    setLoading(true);
    const retorno = await fetchData.getPaymentSession(data, fpgto, token);      
    
    if(retorno.data.status === 'error'){
      setLoading(false);
      MySwal.fire({
        title: 'Oops!',
        html: retorno.data.message,
        icon: 'error'     
      })
      return;
    }
    
    //-- Pgto por Stripe, redireciona para a URL retornada pela Session
    setLoading(false);

    //-- Pagamento por MPago. Renderiza o botão para pagamento pelo CheckoutPro
    setPreferenceId('');
    if(fpgto === 'mpago'){      
      setPreferenceId(retorno.data.preference_id);
    }    
    window.location.href = retorno.data.url;        
  }



  let valor_numerico = 'R$ 10,00'; 
  switch(valor){    
    case 'preco2': valor_numerico = 'R$ 20,00'; break;
    case 'preco3': valor_numerico = 'R$ 50,00'; break;
    case 'preco4': valor_numerico = 'R$ 80,00'; break;
    case 'preco5': valor_numerico = 'R$ 100,00'; break;
    case 'preco6': valor_numerico = 'R$ 200,00'; break;
    default: valor_numerico = 'R$ 10,00'; 
  } 
  
  return(   
    <>
    {config.recarga_online === '1' && 
        <div className="container--pgto">            
            {/* {console.log(fpgto)} */}

            {config.recarga_mpago === '1' &&
                <div className="ms-3 me-3">                    
                    <input type="radio" className="d-inline p-4 mb-4" checked={fpgto === 'mpago'}  onChange={() => setFPgto('mpago')} />
                    <h6 className="alert-heading d-inline p-2">Mercado Pago</h6><br/>  
                    {config.pgto_pix === '1' && <img src='/assets/images/pix.png' alt="pix"/>}                                                                          
                    {config.pgto_cartao === '1' && <img src='/assets/images/master.png' alt="master"/>}                                
                    {config.pgto_cartao === '1' && <img src='/assets/images/visa.png' alt="visa"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/hipercard.png" alt="hipercard"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/amex.png" alt="amex"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/diners.png" alt="diners"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/elo.png" alt="elo"/>}  
                    {config.pgto_boleto === '1' && <img src="/assets/images/boleto.png" alt="boleto"/>}  
                    {config.pgto_paypal === '1' && <img src="/assets/images/paypal.png" alt="paypal"/>}                      
                </div>
            } 

            {config.recarga_cielo === '1' &&
                <div className="ms-3 me-3">                    
                    <input type="radio" className="d-inline p-4 mb-4" checked={fpgto === 'cielo'}  onChange={() => setFPgto('cielo')} />
                    <h6 className="alert-heading d-inline p-2">Cielo</h6><br/>  
                    {config.pgto_pix === '1' && <img src='/assets/images/pix.png' alt="pix"/>}                                                                          
                    {config.pgto_cartao === '1' && <img src='/assets/images/master.png' alt="master"/>}                                
                    {config.pgto_cartao === '1' && <img src='/assets/images/visa.png' alt="visa"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/hipercard.png" alt="hipercard"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/amex.png" alt="amex"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/diners.png" alt="diners"/>}                                
                    {config.pgto_cartao === '1' && <img src="/assets/images/elo.png" alt="elo"/>}  
                    {config.pgto_boleto === '1' && <img src="/assets/images/boleto.png" alt="boleto"/>}  
                    {config.pgto_paypal === '1' && <img src="/assets/images/paypal.png" alt="paypal"/>}                      
                </div>
            } 

            {config.recarga_stripe === '1' &&
              <div className="ms-3 me-3">                    
                    <input type="radio" className="d-inline p-4 mb-4" checked={fpgto === 'stripe'}  onChange={() => setFPgto('stripe')} />
                    <h6 className="alert-heading d-inline p-2">Stripe</h6><br/>                                                                
                    <img src='/assets/images/master.png' alt="master"/>                                
                    <img src='/assets/images/visa.png' alt="visa"/>                                
                    <img src="/assets/images/hipercard.png" alt="hipercard"/>                                
                    <img src="/assets/images/amex.png" alt="amex"/>                                
                    <img src="/assets/images/diners.png" alt="diners"/>                                
                    <img src="/assets/images/elo.png" alt="elo"/>                      
              </div>
            }    

            <div className="ms-3 me-3">
              <div className="alert alert-primary p-2 ps-3 me-4 mb-4" role="alert">
                {/* <i className="bi-person me-2" style={{width:"50",height:"50"}}></i> */}
                <CashCoin color="royalblue" size={25} />                  
                <span className="ms-3"> Clique para selecionar o valor da recarga: </span>   
              </div>

              <div className="container-valores">                   
                <button type="button" onClick={() => setValor('preco1')} className="botao-valor botao-valor-azul">10,00</button>
                <button type="button" onClick={() => setValor('preco2')} className="botao-valor botao-valor-verde">20,00</button>
                <button type="button" onClick={() => setValor('preco3')} className="botao-valor botao-valor-laranja">50,00</button>
                <button type="button" onClick={() => setValor('preco4')} className="botao-valor botao-valor-rosa">80,00</button>
                <button type="button" onClick={() => setValor('preco5')} className="botao-valor botao-valor-amarelo">100,00</button>
                <button type="button" onClick={() => setValor('preco6')} className="botao-valor botao-valor-preto">200,00</button>                                  
              </div>                  
            </div>  
            <div className="ms-3 me-3 cho-container">       
                <Form className="container-final-pgto" onSubmit={handleSubmit}>                
                  <Form.Group size="sm" controlId="valor">                
                    <Form.Label>Valor da Recarga:</Form.Label>
                    <Form.Control
                        className=""                        
                        type="text"                                                
                        value={valor_numerico}
                        readOnly                            
                        // onChange={(e) => setValor(e.target.value)}
                    />
                  </Form.Group>   
                  <Button variant="success" size="md" type="submit">
                    <Check size={35} />                  
                    Confirmar Pagamento
                  </Button>                          
                </Form >                     
            </div>                

            {/* Não passar por aqui, porque estamos utilizando nosso proprio botão para finalizar
                Para modificar este comportamente, teríamos que criar uma nova tela com um passo a mais
                para finalizar a venda, pois para criar a preferência já precisamos ter o valor da recarga. */}
            {finalizar && fpgto === 'mpago' && 
                <MPagoCheckout preferenceId={preferenceId} mp_public_key={config.mpago_chave_publica} />
            }
        </div>
    } 


    {config.recarga_online === '0' && <RecargaDesativada /> }
    </>   
  )
}

export default Pagamentos;