import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from './global/context' 

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <AppProvider>      
      <App />
    </AppProvider>        
  </React.StrictMode>,
  document.getElementById('root')
);
