import axios from 'axios';

//const API_BASE = 'http://localhost/pagamentos/server/public';
// const API_BASE = 'https://natusnutri-maxi.procardbr.com.br/public';

const API_BASE = ((window.location.href).indexOf('//localhost:3000') > 0) ? 'http://localhost/pagamentos/server/public' : 'https://' + window.location.hostname + '/public';
// console.log(API_BASE);
axios.defaults.timeout = 50000;

const basicFetch = async (endpoint, data, token, method = "POST") => {

    //const token = getToken();
    // axios.defaults.headers.common = {}
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000/';     

    try{
        const res = await axios({
        method: method,
        url: `${API_BASE}${endpoint}`, 
        data: data,
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8", 
            //   "Access-Control-Allow-Origin": "*" 
        }      
        })
        const retorno = {
            items: res.data,
            status: "success",   
            message: res.data.message ? res.data.message : "success",
            code: (res.status) ? res.status : 200           
        }
        //console.log('retorno: ' + JSON.stringify(retorno));
        return retorno;        
    }
    catch (error) {
        const erro = {
            items: [],
            status: "error",               
            message: error.response.data.message ? error.response.data.message : error.message,            
            code: 404
        }          
        //console.log('erro: ' + JSON.stringify(erro));
        return (erro);        
    }    
};


const basicGet = async (endpoint, data, token) => {

    try {
        const res = await axios({
            method: 'GET',
            url: `${API_BASE}${endpoint}`, 
            params: data,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",         
            }      
        })
        const retorno = {
            items: res.data,
            status: "success",   
            messsage: "success",
            code: 200           
        }
        return retorno;    
    }
    catch (error) {
        const erro = {
            items: [],
            status: "error",   
            messsage: error.message,
            code: error.response.status           
        }          
        return (erro);        
    }    
};



const makeLogin  = async (data) => {
    
    const resp = await basicFetch('/auth/login', data);        
    return resp.items;
}


const reset_password  = async (data) => {
    const resp = await basicFetch('/auth/reset_password', data);        
    return resp;
}


const getVendasPeriodo = async (data, token) => {
    
    const retorno = await basicFetch(`/api/vendas/vendas-periodo`, data, token)                 
    return {
        slug: 'vendas_periodo',
        title: 'Vendas no Período',        
        code: retorno.code,
        items: retorno.items                
    };
}

const getProdutosVendidosPeriodo = async (data, token) => {
    const retorno = await basicFetch(`/api/vendas/produtos-periodo`, data, token)                 
    return {
        slug: 'produtos_periodo',
        title: 'Produtos Vendidos no Período',
        code: retorno.code,
        items: retorno.items                
    };
}

const getRecargasPeriodo  = async (data, token) => {
    const retorno = await basicGet(`/api/recargas`, data, token)                 
    return {
        slug: 'recargas_periodo',
        title: 'Recargas no Período',
        code: retorno.code,
        items: retorno.items                        
    };
}

const getResumoConsumo  = async (data, token) => {
    const retorno = await basicFetch(`/api/procard/resumo-consumo`, data, token)        
    return {
        slug: 'resumo_consumo',
        title: 'Resumo Consumo Procard',
        code: retorno.code,
        items: retorno.items                                
    }
}


const getPaymentSession  = async (data, fpgto, token) => {
    
    //-- Default: MPago
    let url = '/mpago_pagar';
    if(fpgto === "cielo") url = '/cielo_pagar';
    if(fpgto === "stripe") url = '/stripe_pagar';

    const retorno = await basicFetch(url, data, token)
    return {        
        code: retorno.code,
        data: retorno.items
    }    
}


const getConfig = async (token) => {
    
    let retorno = await basicGet(`/api/configuracoes`, [], token);
    return {
        slug: 'configuracoes',
        title: 'Configurações',
        code: retorno.code,
        items: retorno.items
    };
}


//-- Obtém os itens de configuração antes de ter o token
//-- Ou seja, configurações antes de fazer o login
const getConfig2  = async () => {
    const res = await axios({
        method: 'GET',
        url: `${API_BASE}/api/configuracoes2`,         
        headers: {"Content-Type": "application/json;charset=UTF-8"}      
      })
      return (res.data);
  };



const getCardapio = async (data, token) => {
    
    const retorno = await basicFetch(`/api/produtos/cardapio`, data, token)                 
    return {
        slug: 'cardapio',
        title: 'Cardápio',
        code: retorno.code,
        items: retorno.items 
    };
}


const saveConfig = async (data, token) => {
    const retorno = await basicFetch(`/api/configuracoes`, data, token)
    return {        
        code: retorno.code,        
        data: retorno.items
    }    
}


const saveProcard = async (data, token) => {
    const retorno = await basicFetch(`/api/procards/save`, data, token)
    return {        
        code: retorno.code,        
        message: retorno.message,
        status: retorno.status
    }    
}

const saveProcardProdutosProibidos = async (data, token) => {
    const retorno = await basicFetch(`/api/produtos/saveProcardProdutosProibidos`, data, token)
    return {        
        code: retorno.code,        
        data: retorno.items
    }    
}


const changePassword = async (data, token) => {
    return await basicFetch(`/api/change_password`, data, token)    
}


const fetchData = {
    basicFetch,
    getVendasPeriodo,
    getProdutosVendidosPeriodo,
    getRecargasPeriodo,
    makeLogin,
    getPaymentSession,
    getResumoConsumo,
    getConfig, getConfig2, saveConfig, saveProcardProdutosProibidos,
    getCardapio, changePassword, reset_password, saveProcard
};

export default fetchData


/*
.then(function (res) {
        return (res.data);
    })
    .catch(function (error) {
        if (error.response) {
        //   console.log(error.response.data);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
          
          //-- sessao expirada somente se token foi enviado
          if(token && error.response.status === 401){
            sessionStorage.clear();
            window.location.pathname = '/';
          } 

          //-- outros erros: retorna o erro completo
          const erro = {
              status: error.response.status,   
              messsage: error.response.data,
              statusText: error.response.statusText           
          }          
          return (erro);
        }
    });  

.then(res => {
        return (res.data);
    })
    .catch(error => {
        if (error.response) {
          //-- sessao expirada somente se token foi enviado
          if(token && error.response.status === 401){
            sessionStorage.clear();
            window.location.pathname = '/';
          } 
        }        
        //-- outros erros: retorna o erro completo
        const erro = {
            items: [],
            status: "error",   
            messsage: error.message,
            code: error.code           
        }          
        return (erro);
    });       
*/

