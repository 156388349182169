import React, {useState, useContext} from 'react';
import fetchData from '../../global/FetchData';
import FormPeriodo from '../../components/FormPeriodo/FormPeriodo';
import CustomTable from '../../components/CustomTable';
import moment from 'moment';
import { AppContext } from '../../global/context' 


function Consumo({setLoading, setTitle}) {    
    
    const [tableData, setTableData] = useState([]);
    const {dataIni, dataFim, getActiveProcard, token, refresh} = useContext(AppContext); 

    React.useEffect(() => {
      handleClick();
      setTitle('Resumo Consumo Procard');
    }, [refresh]);

    
    const handleClick = async () => {
      
      setLoading(true);
      const procard = getActiveProcard();
      const dados = {
        id_procard: procard.id,
        dataIni: dataIni.toISOString().split('T')[0],
        dataFim: dataFim.toISOString().split('T')[0],
      }      
      const retorno = await fetchData.getProdutosVendidosPeriodo(dados, token);

      //-- Sessão expirada
      if(retorno.code === 401){
        setLoading(false);
        sessionStorage.clear();    
        window.location.href = '/';      
      }
      setTableData(retorno.items)
      setLoading(false);
    }    

    const columns = [        
        {  name: 'DATA/HORA', selector: row => moment(row.data_hora_venda).format('DD/MM/YYYY HH:mm'), sortable: true, minWidth: '130px'},
        {  name: 'PRODUTO', selector: row => row.descricao, sortable: true, minWidth: '190px'},
        {  name: 'QTDE.', selector: row => (parseFloat(row.quantidade)).toFixed(2), sortable: true},        
        {  name: 'VLR. UNIT.', selector: row => (parseFloat(row.valor_unitario)).toFixed(2), sortable: true, hide: 'sm'},
        {  name: 'VLR. TOTAL', selector: row => (parseFloat(row.total)).toFixed(2), sortable: true},
        {  name: 'TIPO', selector: row => row.cor, sortable: false, hide: 'sm'},        
        {  name: 'ID', selector: row => row.id, sortable: true, hide: 'sm' },
    ];

    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                                
            <FormPeriodo handleClick={handleClick}/>   
            <CustomTable 
              title="Produtos Consumidos" 
              columns={columns} 
              data={tableData} 
              //progressPending={progressPending}
            />                         
        </div>  
      </div>            
  )
}

export default Consumo;