import React, { useContext } from "react";
import fetchData from "../../global/FetchData";
import "./Config.scss";
import { AppContext } from "../../global/context";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


function LimiteDiario({ setLoading, setTitle }) {
    
    const MySwal = withReactContent(Swal);
    const { token } = useContext(AppContext);
    const [limite, setLimite] = React.useState(0); 
    
    //-- recebo os parametros do context    
    const {getActiveProcard, updateLimiteCreditoLocal} = useContext(AppContext);       


    React.useEffect(() => {
        setTitle("Limite Diário no Procard");
        //setProcards(getProcards())
        const active = getActiveProcard()      
        function load(){            
            if(active){                
                const _limite = active.limite_diario;
                setLimite(_limite);        
            }      
        }
        load(); 
        setLoading(false);    
    }, [getActiveProcard])   
    
 
    function handleChange(evt) {      
      setLimite(evt.target.value);    
    }


    async function handleSubmit(event) {        
      
      setLoading(true);  
      event.preventDefault();
        
      const procard = getActiveProcard();
      const data = {id: procard.id, limite_diario: limite, updated_online: '1'}
      const retorno = await fetchData.saveProcard(data, token);
      setLoading(false); 
      if (retorno.status === "error") {
          MySwal.fire({
              title: "Oops!",
              html: retorno.message,
              icon: "error",
          });
          return;
      }     

      //-- Atualiza o limite de crédio do procard ativo na session
      updateLimiteCreditoLocal(limite);      

      //   setState({...state, ['king_pass']: ""});        
      MySwal.fire({
        title: "Tudo certo!",
        html: retorno.message,
        icon: "success",
      });
    }


    return (
        <form onSubmit={handleSubmit}>
            
            <div className="row justify-content-end mt-3">
                <div className="col-12 col-sm-3 me-2">
                    
                </div>                
            </div>
            <section className="config-section">
                <div className="left-box">
                    <div className="row mb-3">                            
                        <div className="col-6 col-sm-3 pe-0 mb-3">
                            <label htmlFor="limite" className="form-label">
                                Limite Diário
                            </label>
                            <input name="limite" type="number" className="form-control" value={limite} onChange={handleChange} />
                            <div id="emailHelp" className="form-text">
                                Limite diário de compras no Procard
                            </div>
                        </div>
                    </div>
                    <div className="row">                         
                        <div className="col-6 col-sm-3">
                            <button type="submit" className="btn btn-success float-start">
                                Salvar Limite
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    );
}

export default LimiteDiario;
