import React from 'react';
import { Link } from 'react-router-dom';
import './Pagamentos.scss'
import {Handbag, ExclamationCircleFill} from 'react-bootstrap-icons';

function Pending({setLoading}) {    

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, [setLoading])  
  
  return(    
    <>
    <div className="congrats-page">  
      <div className="congrats-container congrats-pendente">  
        
        <div className="congrats-badge">          
          <span className="congrats-badge__title">Procard</span>
          <span className="congrats-badge__text">PGTO. RECARGA</span>           
        </div>

        <div className="congrats--header">          
            <div className="icone">            
              <Handbag /> 
              <ExclamationCircleFill className="icon-warning-badge"/>
            </div>
            <div className="congrats-titles">
              <p>Pagamento em processamento...</p>
              <h4>Seu pagamento ainda não foi efetivado!</h4>
            </div>
        </div>
      </div>

      <div className="congrats--content">
        <div className="ui-card-wrapper">
          <div className="ui-card">
            <h2 className="h2">O que posso fazer?</h2>
            <p  className="text mb-2">A operadora do cartão ainda está processando seu pagamento.</p>
            <p  className="text mb-2">Assim que o pagamento for liberado, a recarga será aplicada no Procard.</p>
            <p  className="text mb-4">Você pode acompanhar o status na página de recargas.</p>            
            <div className="group-button">
              <Link to="/recargas" className="ui-button">Ver lista de recargas</Link>                
            </div>
            <p className="congrats-text-alert">Importante: A recarga leva em média 02 horas para ser efetivada no cartão do aluno.<br/>
                                     Isto depende da velocidade da internet no restaurante/escola e da demanda no servidor de recargas.</p>
          </div>
        </div>              
      </div>    
    </div>  

    </>       
  )
}


export default Pending;