import React, {useContext, useState} from 'react';
import './TopBar.scss';
import { AppContext } from '../../global/context' 
import {CreditCard2Front} from 'react-bootstrap-icons';
import {Person} from 'react-bootstrap-icons';
import {Wallet} from 'react-bootstrap-icons';
import {List} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';

function TopBar({title}) {

  const [isMobile] = useState(document.body.clientWidth <= 498);

  //-- recebo os parametros do context    
  const {getActiveProcard, setActiveProcard, isSidebarOpen, setIsSidebarOpen, getProcards, refresh, setRefresh} = useContext(AppContext);   
  const [sicron, setSicron] = useState(0);   
  const [procard, setProcard] = useState(); 
  const [procards, setProcards] = useState();   

  React.useEffect(() => {      
    setProcards(getProcards())
    const active = getActiveProcard()      
    function loadAll(){            
      if(active){                
        const aux = active.data_sincronismo.substring(0,10);
        const data = parseDate(aux, 'yyyy-mm-dd')
        const hora = active.data_sincronismo.substring(11,16);
        const sicron = data.toLocaleDateString()+' '+hora;   
        
        let saldo = parseFloat(active.saldo);
        saldo = (saldo.toFixed(2)).replace('.', ',');
        active.saldo = saldo;

        setSicron(sicron);        
        setProcard(active);        
      }      
    }
    loadAll();    
  }, [setSicron, getActiveProcard, getProcards, refresh])   


  React.useEffect(() => {    
    setIsSidebarOpen(!isMobile); 
  }, [isMobile, setIsSidebarOpen]) 



  function parseDate(input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    var parts = input.match(/(\d+)/g), 
        i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function(part) { fmt[part] = i++; });
  
    return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
  }


  function handleChange(evt) {
    const value = evt.target.value;
    //const procard = procards.find(x => x.numero === value);
    //console.log(procard);
    setActiveProcard(value);  
    setProcard(getActiveProcard()); 
    setRefresh(!refresh);
  }



  return (
    <>
      {procards && procard && <>
      <nav className="navbar-prodabit">                      
        <List className="menu-hamburguer" onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
        <h5>{title}</h5>
      </nav>

      <div className="info-user">
        <div className='select--procard'>
          <CreditCard2Front className="mb-1 me-1" color="#3a0733" size={15} />
          <b> PROCARD:</b>    
          {procards.length > 1 &&
          <Form.Select size="sm" name="numero_procard" onChange={handleChange}>          
            {procards.map(item =>
              <option key={item.id} value={item.numero}>{item.numero}</option>
            )};                    
          </Form.Select> 
          }
          {procards.length === 1 && <span className="ms-2">{procards[0].numero}</span>}          
        </div>  
        <div>
          <Person className="mb-1 me-1" color="#3a0733" size={15} />        
          <b className="me-2"> USUÁRIO:</b> {procard.usuario}
        </div>
        <div>  
          <Wallet className="mb-1 me-1" color="#3a0733" size={15} />  
          <b> SALDO ATUAL: {procard.saldo} </b>
        </div>  
        <div>
          <span className="blockquote-footer">Sincronizado em: {sicron}</span>
        </div>
      </div>
    </>}
  </>  
  )
}

export default TopBar;
