import React from 'react';
import { Link } from 'react-router-dom';
import './Pagamentos.scss'
import {Handbag, ExclamationCircleFill} from 'react-bootstrap-icons';


function Erro({setLoading}) {    

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, [setLoading])  
  
  
  return(    
    <>
    <div className="congrats-page">  
      <div className="congrats-container congrats-erro">  
        
        <div className="congrats-badge">          
          <span className="congrats-badge__title">Procard</span>
          <span className="congrats-badge__text">PGTO. RECARGA</span>           
        </div>

        <div className="congrats--header">          
            <div className="icone">            
              <Handbag /> 
              <ExclamationCircleFill className="icon-warning-badge"/>
            </div>
            <div className="congrats-titles">
              <p>Algo deu errado...</p>
              <h4>Não foi possível processar o pagamento da recarga!</h4>
            </div>
        </div>
      </div>

      <div className="congrats--content">
        <div className="ui-card-wrapper">
          <div className="ui-card">
            <h2 className="h2">O que posso fazer pare resolver?</h2>            
            <p  className="text mb-2">Pode ser somente um problema temporário no servidor de pagamentos.</p>
            <p  className="text mb-2">Por favor, tente novamente dentro de alguns minutos e se o problema persistir, entre em contato com a cantina/escola.</p>
            <p  className="text mb-4">Clique no botão abaixo para ser direcionado para a página de recargas.</p>
            <div className="group-button">
              <Link to="/recargas" className="ui-button">Ver lista de recargas</Link>                
            </div>            
          </div>
        </div>              
      </div>    
    </div>  

    </>       
  )  
}
  
  
export default Erro;