import React from 'react';
import './Footer.scss'

function Footer() {
  return (
    <footer className="my-1 pt-5 text-muted text-center text-small">
        <p className="mb-1">&copy; 2006-2023 - <a href="http://www.prodabit.com.br" target="_blank" rel='noreferrer'>Prodabit Sistemas</a></p>
        <ul className="list-inline">
            <li className="list-inline-item"><a href="!#">Privacidade</a></li>
            <li className="list-inline-item"><a href="!#">Termos</a></li>
            <li className="list-inline-item"><a href="!#">Suporte</a></li>
        </ul>
    </footer>   
  )
}

export default Footer;
