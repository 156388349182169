import React from 'react';
import DataTable, { createTheme }  from 'react-data-table-component';
import './index.scss'
import { ChevronDown } from 'react-feather';


export function HeaderTable({title, total}) {
  return (
      <h2 className="table--title">{title}
        {total && <span className="subtotal--title"> {total}</span>}
      </h2>
  )
}


function CustomTable({title, total, data, columns, progressPending, handleClick, pointerOnHover, conditionalRowStyles = []}) {    
    
  const paginationComponentOptions = {
      rowsPerPageText: 'Itens por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',
  };

  return( 
      <div className="mt-4">  
        <DataTable className="table table-striped"
            // title={title}
            title={<HeaderTable title={title} total={total}/>}
            columns={columns} 
            data={data} 
            pagination paginationComponentOptions={paginationComponentOptions}
            responsive
            striped
            highlightOnHover
            customStyles={customStyles}
            progressPending={progressPending}
            persistTableHead
            noDataComponent = "Nenhum dado foi retornado da consulta atual!"
            //subHeader
            //subHeaderAlign="right"
            //subHeaderWrap
            //theme="solarized"            
            //selectableRows
            sortIcon = {<ChevronDown />}
            onRowClicked={handleClick}
            pointerOnHover={pointerOnHover}
            //conditionalRowStyles={conditionalRowStyles}
        />                         
      </div>
  )
}


// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('solarized', {
    text: {
      primary: '#5b6c78',
      secondary: '#83878b',      
    },
    background: {
      default: '#fff',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#ccc',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
}, 'default');

const customStyles = {
  rows: {
  },
  headCells: {
      style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',            
          backgroundColor: '#e5e4e47a',
          borderRadius: '2px'
      },
  },
  cells: {
      style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
      },
  },
  header: {
		style: {
			fontSize: '1rem',						
		},
	},  
};


export default CustomTable;