import React from 'react';
import { Link } from 'react-router-dom';
import {Handbag, ExclamationCircleFill} from 'react-bootstrap-icons';
import './Pagamentos.scss'
// import logo from '../../assets/images/logo-prodabit-transp.png'

function Sucesso({setLoading}) {    

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, [setLoading])  
  
  return(    
    <>
    <div className="congrats-page">  
      <div className="congrats-container congrats-sucesso">  
        
        <div className="congrats-badge">          
          <span className="congrats-badge__title">Procard</span>
          <span className="congrats-badge__text">PGTO. RECARGA</span>           
        </div>

        <div className="congrats--header">          
            <div className="icone">            
              <Handbag /> 
              <ExclamationCircleFill className="icon-warning-badge"/>
            </div>
            <div className="congrats-titles">
              <p>Tudo certo...</p>
              <h6>Seu pagamento está sendo processado pela operadora!</h6>
              <h5>Assim for confirmado pela Cielo, liberaremos o crédito no cartão do aluno.</h5>
            </div>
        </div>
      </div>

      <div className="congrats--content">
        <div className="ui-card-wrapper">
          <div className="ui-card">
            <h2 className="h2">Onde posso acompanhar s recargas?</h2>
            <p  className="text mb-4">Clique no botão abaixo para ser direcionado para a página de recargas.</p>
            <div className="group-button">
              <Link to="/recargas" className="ui-button">Ver lista de recargas</Link>                
            </div>
            <p className="congrats-text-alert">Importante: A recarga leva em média 02 horas para ser efetivada no cartão do aluno.<br/>
                                     Isto depende da velocidade da internet no restaurante/escola e da demanda no servidor de recargas.</p>
          </div>
        </div>              
      </div>    
    </div>  

    </>       
  )
}


export default Sucesso;