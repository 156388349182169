import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './RecargaDesativada.scss'
// import logo from '../../assets/images/logo-prodabit-transp.png'

function RecargaDesativada() {    

  return(           
    <div className="row m-4">     
      <div class="col-6 alert alert-danger" role="alert">
        Funcionalidade de recarga não habilitada!
      </div>       
      <div className="">                    
        <p>A funcionalidade da recarga online ainda não está ativada para esta escola/cantina.</p>
        <p className="mb-5">Entre em contato com a escola/cantina e solicite a ativação da mesma.</p>
        
        <Link to="/" className="btnVoltar">Ir para a página inicial</Link>
      </div>
    </div>  
  )
}


export default RecargaDesativada;