import React, {useContext, useState, useRef} from 'react';
//import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import fetchData from './global/FetchData';
import { AppContext } from './global/context' 
import Home from './pages/Home/Home'
import Vendas from './pages/Vendas/Vendas'
import Consumo from './pages/Vendas/Consumo'
import Recargas from './pages/Vendas/Recargas'
import Login from './pages/Login/Login'
import Config from './pages/Config/Config'
import LimiteDiario from './pages/Config/LimiteDiario'
import Cardapio from './pages/Cardapio/Cardapio'
import Pagamentos from './pages/Pagamentos/Pagamentos'
import Sucesso from './pages/Pagamentos/Sucesso'
import SucessoCielo from './pages/Pagamentos/SucessoCielo'
import Erro from './pages/Pagamentos/Erro'
import Pending from './pages/Pagamentos/Pending'
import './App.css';

import SideBar from './components/SideBar/SideBar';
import TopBar from './components/TopBar/TopBar';
import Footer from './components/Footer/Footer';
import Loading from './global/Loading';
import AlterarSenha from './pages/Login/AlterarSenha';
import EsqueceuSenha from './pages/Login/EsqueceuSenha';


// import { Camera } from 'react-feather';
//import { ArrowRight } from 'react-bootstrap-icons';

function App() {  

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({});
  const [procard, setProcard] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [title, setTitle] = useState('Resumo do Consumo Procard :: Dashboard');  
  const deviceId = useRef() 
  const {getActiveProcard} = useContext(AppContext);   
 
  React.useEffect(() => {    
    const loadAll = async () => {
      const res = await fetchData.getConfig2(); 
      if(res) setConfig(res);      
      
      const active = getActiveProcard()
      setProcard(active)      
      setPageLoaded(true);
    }

    loadAll();
  }, [getActiveProcard])  


  //-- Se página já foi carregada. Já passou pelo UseEffect()  
  if(pageLoaded && !procard) {                
    return(
      <Router basename="/">
        <Routes>              
          <Route exact path="/" element={<Login setLoading={setLoading} />}></Route>
          <Route exact path="/resetar_senha" element={<EsqueceuSenha setLoading={setLoading} setTitle={setTitle} />}></Route>
        </Routes>                        
      </Router>    
    ) 
  }  

  
 
  return (      
      <Router basename="/">
        {procard && 
        <div className="d-flex" id="wrapper">
            {procard.first_access === '0' && <SideBar /> }           
            <div id="page-content-wrapper">
              <input type="hidden" id="deviceId" ref={deviceId}/>
              <TopBar title={title}/>
              <Routes>              
                {/* <Route exact path="/logout" render= {() => console.log('Heading to /')} element={<Login setToken={setToken}/>}></Route>  */}
                <Route exact path="/vendas" element={<Vendas setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/consumo" element={<Consumo setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/recargas" element={<Recargas setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/logout" element={<Login />}></Route>
                <Route exact path="/alterar_senha" element={<AlterarSenha setLoading={setLoading} setTitle={setTitle} firstAccess={procard.first_access}/>}></Route>
                <Route exact path="/configuracao" element={<Config setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/cardapio" element={<Cardapio setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/limite-diario" element={<LimiteDiario setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/add-creditos" element={<Pagamentos setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>
                <Route path="/pgto-sucesso" element={<Sucesso setLoading={setLoading} setTitle={setTitle}/>}>
                  <Route path=":section_id" element={<Sucesso />} />
                </Route>              
                {/* <Route path="/pgto-sucesso/:session_id" element={<Sucesso />}></Route> */}
                <Route path="/pgto-erro" element={<Erro setLoading={setLoading} setTitle={setTitle} />}>
                    <Route path=":section_id" element={<Erro />} />  
                </Route>              
                <Route path="/pgto-pendente" element={<Pending setLoading={setLoading} setTitle={setTitle} />}>
                    <Route path=":section_id" element={<Pending />} />  
                </Route>              
                <Route exact path="/pgto-concluido" element={<SucessoCielo setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/cardapio" element={<Cardapio setLoading={setLoading} setTitle={setTitle}/>}></Route>
                
                
                {/* Altera a homepage de acordo com a configuração "Home" */}
                {(procard.first_access === '1' || config.home === 'senha') && <Route exact path="/" element={<AlterarSenha  setLoading={setLoading} setTitle={setTitle} firstAccess={procard.first_access}/>}></Route>}
                {(config.home === 'dash' || config.home === '') && <Route exact path="/" element={<Home setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>}
                {config.home === 'compras'  && <Route exact path="/"  element={<Vendas    setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>}
                {config.home === 'produtos' && <Route exact path="/" element={<Consumo    setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>}
                {config.home === 'recarga'  && <Route exact path="/" element={<Pagamentos setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>}
                {config.home === 'recargas' && <Route exact path="/" element={<Recargas   setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>}
                {config.home === 'cardapio' && <Route exact path="/" element={<Cardapio   setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>}
                
                <Route exact path="/" element={<Pagamentos setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>
                <Route path="*" component={() => "404 - Página não encontrada!"} />                                         
              </Routes>                      
              <Footer />
            </div>          
        </div>
        }
        {loading && <div className="overlay"><Loading /></div>}
      </Router> 
  );
}

export default App;

