import React, {useState, useContext} from 'react';
import fetchData from '../../global/FetchData';
import FormPeriodo from '../../components/FormPeriodo/FormPeriodo';
import CustomTable from '../../components/CustomTable';
import moment from 'moment';
import { AppContext } from '../../global/context' 
import {Circle, Check2All} from 'react-bootstrap-icons';


function Recargas({setLoading, setTitle}) {    
    
    const [tableData, setTableData] = useState([]);    
    const [total, setTotal] = useState(0);
    const {dataIni, dataFim, getActiveProcard, token, refresh} = useContext(AppContext); 

    React.useEffect(() => {
        handleClick();
        setTitle('Resumo Recargas Procard');
    }, [refresh]);

    
    const handleClick = async () => {
      
      setLoading(true);
      const procard = getActiveProcard();
      const dados = {
        id_procard: procard.id,
        dataIni: dataIni.toISOString().split('T')[0],
        dataFim: dataFim.toISOString().split('T')[0],
      }      
      const retorno = await fetchData.getRecargasPeriodo(dados, token);

      //-- Sessão expirada
      if(retorno.code === 401){
        setLoading(false);
        sessionStorage.clear();    
        window.location.href = '/';      
      }

      let vtotal = 0;     
      const aux = retorno.items.map((item) => {          
          item.sincronizado = (item.sincronizado === '1') ? <Check2All className="sucesso"/> : <Circle className="warning" />
          item.status_pgto = (item.status_pgto === 'aprovado') ? <span className="text-success">{item.status_pgto}</span> : <span className="text-warning">{item.status_pgto}</span>
          return item
      })                      
      setTableData(aux)              
      for (var i in retorno.items) {
        vtotal += parseFloat(retorno.items[i].valor);
      }
      setTotal(vtotal);      
      setLoading(false);
    }   
    
    

    const columns = [
        {  name: 'DATA/HORA', selector: row => (moment(row.created_at).format('DD/MM/YYYY HH:mm')) , sortable: true, minWidth: '130px'},        
        // {  name: 'HORA', selector: row => (moment(row.created_at).format('HH:mm')) , sortable: false},                
        {  name: 'VALOR', selector: row => (parseFloat(row.valor)).toFixed(2), sortable: true, MaxWidth: '100px'},
        {  name: 'STATUS PGTO.', selector: row => row.status_pgto, sortable: true},  
        {  name: 'SINCRON.', selector: row => row.sincronizado, sortable: false, center: true},          
        {  name: 'LOCAL/F.PGTO', selector: row => (row.local +'/'+ row.payment_method), sortable: true},                        
        {  name: 'ID|ID PGTO.', selector: row => (row.id + '/' + row.id_pagamento), sortable: true, hide: 'sm'},  
    ];

    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                                
            <FormPeriodo handleClick={handleClick}/>  
            <CustomTable 
              title="Relatório de Recargas" 
              total={`(Total R$ ${total.toFixed(2)})`}  
              columns={columns} 
              data={tableData}               
            />                         
        </div>  
        <div className="mensagem--user">Sincronizado: <Check2All className="sucesso" /> Indica que a recarga já foi aplicada ao cartão do usuário/aluno.</div>
      </div>
  )
}

export default Recargas;